.login-page-layout {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  .login-banner {
    width: 100%;
    height: 100%;
    position: relative;
    // font-family: Lato, Semibold;
    .left-transparent {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
      border: 1px solid #ebf1f5;
      background: transparent
        url("../../assests/images/Rectangle\ 13\ \(1\).svg") 0% 0% no-repeat
        padding-box;
    }
    .right-transparent {
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      height: 100%;
      border: 1px solid #ebf1f5;
      background: transparent
        url("../../assests/images/Rectangle\ 1\ \(1\).svg") 0% 0% no-repeat
        padding-box;
    }

    .login-card-body {
      width: 700px;
      height: 500px;
      position: absolute;
      background-color: #ffffff;
      top: calc((100% - 500px) / 2);
      left: calc((100% - 700px) / 2);
      border-radius: 10px;

      .txt-welc {
        color: #5262a3;
        font-size: 1.5rem;
        font-weight: 500;
        // border:3px solid red;
      }

      .txt-yr-acc {
        color: #a7a7a7;
        font-size: 1.1rem;
        font-weight: 400;
        // border:3px solid green;
      }
      .sign-in-btn {
        color: #5262a3;
        position: absolute;
        background-color: #e5e5e5;
        border: 1px solid #e5e5e5;
        width: 100%;
        height: 2.5rem;
        bottom: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        font-weight: 500;
        font-size: 16px;
      }
      .label-text {
        color: #757575;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 8px;
        padding: 0;
      }
      .input-container {
        border-radius: 10px;
        width: 100%;
        border-color: #5262a3;
        transition: all 0.1s ease-in-out;
        height: 40px;
        padding: 0;
      }
      .input-container:focus {
        border-width: 3px;
      }
      .input-element {
        border-width: 0;
        outline: none;
        padding: 0px 8px;
        border-radius: 10px 0 0 10px;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .input-icon {
        font-size: 30px;
        color: #757575;
        margin-right: 10px;
        margin-left: 10px;
      }
      .sign-up-text {
        font-size: 14px;
        color: #757575;
        cursor: pointer;
      }
      .sign-up-text-highlighted {
        color: #5262a3;
        font-weight: 500;
      }

      .select-role-container {
        border-radius: 50%;
        background-color: #e0e5f8;
        color: #4d4c4c;
        font-weight: 500;
        font-size: 16px;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #ebf1f5;
        padding: 40px 40px;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-in-out;
      }

      .select-role-container:hover {
        cursor: pointer;
        background-color: #8ec5fc;
        background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
        box-shadow: 4px 6px 8px #00000029;
        transform: scale(1.1);
        border: 1px solid #ebf1f5;
      }

      .active-role {
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        background-color: #75b7fa;
        background-image: linear-gradient(62deg, #69b1fa 0%, #d1a4fc 100%);
        box-shadow: 4px 6px 8px #00000029;
        transform: scale(1.2);
        border: 1px solid #ebf1f5;
        padding: 40px 40px;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-weight: 500;
        font-size: 16px;
      }

      .back-text {
        color: #5262a3;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  .resend-otp-text {
    color: #5262a3;
    font-size: 16px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 750px) {
  .actual-login-cont {
    // border: 30px solid red !important;
    height: 100vh !important;
    width: 100% !important;
  }
  .login-page-layout {
    // border: 30px solid forestgreen !important;
    position: relative !important;
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    overflow-x: hidden;
    // display: flex !important;
    // justify-content: start;
    // align-items: start;
  }
  .actual-login-cont {
    height: 100vh !important;
    // border: 30px solid black;
  }

  .login-card-body {
    width: 100% !important;
    height: 100% !important;
    top: 0px !important;
    left: 0px !important;
  }

  .i-vanish-mobile-view {
    display: none;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.admin-layout-bg-container {
  background-color: #f3f3f3;
  width: 100%;
  max-width: 100vw;
  // border: 2px solid red !important;

  .admin-sidebar {
    width: 350px;
    min-width: 350px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ffffff;
    border-radius: 36px;
    transition: all 0.4s ease-in-out;
    height: 95vh !important;

    .admin-sidebar-image-logo {
      width: 30%;
    }

    .sidebar-menu-container {
      overflow-y: auto;
      margin: 40px 0 40px 0;

      .menu-container {
        cursor: pointer;
        height: 40px;

        //
      }

      .menu-container:hover {
        background: -webkit-linear-gradient(left, #ffffff 50%, #2e3b83 50%);
        background: -moz-linear-gradient(left, #ffffff 50%, #2e3b83 50%);
        background: linear-gradient(to right, #ffffff 50%, #2e3b83 50%);

        background-size: 200% 100%;
        background-size: 200%;
        //
        // background: #2e3b83 50%;
        //
        -webkit-transition: background-position 0.3s ease-in-out;
        -moz-transition: background-position 0.3s ease-in-out;
        transition: background-position 0.3s ease-in-out;
        color: white !important;
        background-position: -100% 0;
        border-right: 3px solid #ffffff;

        //
        //
        .sidebar-text {
          color: #ffffff;
        }

        .sidebar-icons {
          fill: #ffffff;
        }
      }

      .sidebar-icons {
        height: 20px;
        width: 20px;
        transition: all 0.4s ease-in-out;
      }
      .sidebar-text {
        color: #2e3c83;
        font-size: 16px;
        font-weight: 600;
        transition: all 0.4s ease-in-out;
      }

      .sidebar-active-menu {
        background-color: #2e3c83;
        border-right: 3px solid #ffffff;
        box-shadow: #2e3b83 0px 20px 30px -10px;

        .sidebar-text {
          color: #ffffff;
        }

        .sidebar-icons {
          fill: #ffffff;
        }
      }
    }

    .sidebar-menu-container::-webkit-scrollbar {
      display: none;
    }
  }

  // .admin-content-panel {
  // }

  .sidebar-circle {
    position: relative;

    left: calc(100% - 15px);
    top: 10%;
    // height: 44px;
    border-radius: 50%;
    font-size: 16px;
    color: #f1f1f2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 30px;
    // margin-left: 15px;
    // margin-top: 20px;
    // left: 93%;
    // top: calc(50% - 22px);
    // left: calc(100% - 22px);
    z-index: 3;
    cursor: pointer;

    .sidebar-circle-icon {
      color: #2e3b83;
      font-size: 30px;
      transition: all 0.4s ease-in-out;
    }
  }

  .admin-search-container {
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ffffff;
    border-radius: 97px;
    width: 50%;
    height: 55px;

    .admin-search-input-element {
      border-width: 0;
      outline: none;
      border-radius: 97px;
    }
  }

  .admin-profile-pic {
    width: 50px;
    height: 50px;
  }

  .admin-name {
    font-family: Montserrat;
    color: #333333;
    font-size: 16px;
    font-weight: 500;
  }

  .role {
    font-family: Montserrat;
    color: #2e3b83;
    font-size: 18px;
    font-weight: 900;
  }

  .notification-bell:hover {
    // transform: scale(1.2);
    // transition: all 0.2s ease-in-out;
    // color: #2e3b83 !important;
    // z-index: 3;
    // position: relative;
  }

  .dropdown-menu {
    // transition: all 0.2s ease-in-out;
    margin-top: 5px !important;
  }

  //  ********dropdown arrow********
  .dropdown-menu::before {
    border-bottom: 9px solid rgba(0, 0, 0, 0.2);
    border-left: 9px solid rgba(0, 0, 0, 0);
    border-right: 9px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    left: 45%; /* position */
    position: absolute;
    top: -8px;
  }

  .dropdown-menu::after {
    border-bottom: 8px solid #ffffff;
    border-left: 9px solid rgba(0, 0, 0, 0);
    border-right: 9px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    left: 45%; /* position */
    position: absolute;
    top: -7px;
  }

  // **** scrollbar *********
  /* width */
  ::-webkit-scrollbar {
    width: 12px;
    transition: all 0.2s ease-in-out;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  // ******* for outlet ******
  .admin-content {
    overflow-y: auto;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    height: 550px;
    width: 100%;
  }

  // ******* for outlet Buyer ******
  .buyer-content {
    overflow-y: scroll;
    // padding: 10px 0px;
    // border: 3px solid green;
    overflow-x: hidden;
    // flex-flow: column;
    // flex-grow: 1;
    height: 100vh;
    width: 100%;
    // border: 3px solid black;
    margin: 0px 0px 30px 0px !important;
    padding-bottom: 60px !important;
  }
}

@media only screen and (max-width: 991px) and (min-width: 767px) {
  .admin-sidebar {
    width: 120px !important;

    .admin-sidebar-image-logo {
      margin-top: 40px;
    }

    .sidebar-text {
      display: none !important;
    }

    .sidebar-circle {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .admin-layout-bg-container {
    display: none !important;
  }

  .active-navbar {
    background-color: #8ec5fc;
    background-image: linear-gradient(
      62deg,
      #8ec5fc 0%,
      #e0c3fc 50%,
      #f3f3f3 100%
    );
  }

  .admin-layout-small-devices-bg-container {
    // display: flex;
    max-width: 768px;
    overflow-y: hidden;
    height: 100vh;
    padding: 0px;
    // border: 3px solid rgb(255, 13, 13);

    .topnav {
      width: 100%;

      // .only-navbar {
      // }

      .mobile-navbar-image-logo {
        width: 50px;
      }

      .mobile-sidebar-active-menu {
        background-color: #2e3b83;
        padding: 0 10px;
        border-radius: 10px;

        .sidebar-icons {
          fill: #ffffff;
        }

        .sidebar-text {
          color: #ffffff;
        }
      }
    }

    .sidebar-text {
      font-size: 20px !important;
      font-weight: 500;
    }

    .mobile-nav-line {
      border: 1px solid;
      border-radius: 20px;
    }
  }

  // dropdown arrow
  // .dropdown-menu::before {
  //   border-bottom: 9px solid rgba(0, 0, 0, 0.2);
  //   border-left: 9px solid rgba(0, 0, 0, 0);
  //   border-right: 9px solid rgba(0, 0, 0, 0);
  //   content: "";
  //   display: inline-block;
  //   left: 80%; /* position */
  //   position: absolute;
  //   top: -8px;
  // }

  // .dropdown-menu::after {
  //   border-bottom: 8px solid #ffffff;
  //   border-left: 9px solid rgba(0, 0, 0, 0);
  //   border-right: 9px solid rgba(0, 0, 0, 0);
  //   content: "";
  //   display: inline-block;
  //   left: 80%; /* position */
  //   position: absolute;
  //   top: -7px;
  // }
}

// temp underconstruction page
.tape {
  font-size: 3em;
  margin: 5% 0 0;
  height: 160px;
  background-image: -webkit-linear-gradient(0deg, #222, transparent, #222),
    linear-gradient(transparent 30px, #ff0 30px),
    repeating-linear-gradient(
      45deg,
      transparent,
      transparent 35px,
      #ff0 35px,
      #ff0 70px
    );
  background-image: -moz-linear-gradient(90deg, #222, transparent, #222),
    linear-gradient(transparent 30px, #ff0 30px),
    repeating-linear-gradient(
      45deg,
      transparent,
      transparent 35px,
      #ff0 35px,
      #ff0 70px
    );
  background-image: -o-linear-gradient(90deg, #222, transparent, #222),
    linear-gradient(transparent 30px, #ff0 30px),
    repeating-linear-gradient(
      45deg,
      transparent,
      transparent 35px,
      #ff0 35px,
      #ff0 70px
    );
  background-image: linear-gradient(90deg, #222, transparent, #222),
    linear-gradient(transparent 30px, #ff0 30px),
    repeating-linear-gradient(
      45deg,
      transparent,
      transparent 35px,
      #ff0 35px,
      #ff0 70px
    );
  background-size: auto, auto 132px, auto;
}
.tape span {
  cursor: default;
  color: #222;
  display: block;
  padding: 1em;
  white-space: nowrap;
}

// kyc verification
.admin-kyc-verification-container {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  overflow-y: auto;
  height: 100vh;

  .container-with-heading {
    // box-shadow: 0px 3px 6px #00000012;
    // border: 3px solid black;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 12px 12px 8px 8px;
  }

  .kyc-content-title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: #aeb7e5;
    color: white;
    height: 40px;
    border-radius: 12px 12px 0 0;
  }

  .details-container {
    padding: 20px;

    .kyc-fields-container {
      // display: flex;
      // flex-direction: column;
      // gap: 4px;
      // width: 100%;
      min-width: 200px;
      max-width: 100%;

      .kyc-label-text {
        color: #414141;
        font-size: 16px;
      }

      .kyc-input-field {
        border: 1px solid #a3a4a7;
        border-radius: 6px;
        width: 100%;
      }
    }
  }
}

// details page

.kyc-back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 35px;
  font-weight: 800;
  background-color: #2e3c83;
  color: #ffffff;
  border: 1px solid #2e3c83;
  border-radius: 21px;
}

.details-page-container {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  background-color: #ffffff;
}
.details-header {
  background-color: #aeb7e5;
  height: 40px;
  color: #ffffff;
  width: 100%;
  // border-radius: 12px 12px 0 0;
  border-radius: 12px;
  padding: 4px 6px;
  font-size: 20px;
  font-weight: 500;
}

.details-page-table-container {
  overflow-x: auto !important;
  // border-radius: 12px 12px 0 0;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #ffffff;
  //
  width: 100%;

  .details-page-table {
    width: 100%;
    border-radius: 8px !important;
    max-width: 100vw;

    thead {
      background-color: #aeb7e5;
      height: 40px;
      color: #ffffff;

      tr {
        text-align: center;
        th {
          font-weight: 500;
          font-size: 18px;
        }
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;

        td {
          color: #3e3e3e;
          font-size: 16px;
        }
      }
      .total-table-text {
        text-align: right;
      }
    }
  }
}

// .d-flex.justify-content-between.align-items-center.pe-3.search {
//   height: 100%;
// }
