.table-inner-cont::-webkit-scrollbar {
  width: 12px;

  height: 12px;
}

.buyer-table-container {
  border-radius: 0px 6px 24px 24px !important;
  box-shadow: 0px 3px 6px #00000029;
  flex-direction: column;
  overflow-x: hidden !important;
  box-sizing: border-box;
  // margin: 20px 0px 0px 0px;
  // border: 1px solid black;

  .table-inner-cont {
    box-sizing: border-box;
    overflow-x: scroll !important;
    // overflow-y: hidden !important;
    width: 100%;
  }

  .buyer-table {
    margin: 0px;
    border-radius: 8px !important;
    border-spacing: 0;
    width: 100%;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      border: 1px solid #aeb7e5;
      border-radius: 24px;
      white-space: pre-wrap;
      tr {
        text-align: center;
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;
        .row-hight-light-txt {
          cursor: pointer;
        }

        .remove-underline-link {
          text-decoration: none;
          color: #aeb7e5;
          // cursor: pointer;
        }

        td {
          color: #3e3e3e;
          white-space: nowrap;
          overflow: hidden;
          font-weight: 600;
          font-size: 16px;
          min-width: 180px !important;
        }

        .pointer-cursor {
          cursor: pointer;
          color: #aeb7e5;
          border: 0px;
        }

        .hightLight-td {
          background-color: #aeb7e5;
          color: #ffffff;
          border: 1px solid #929ece;
          padding: 4px 6px;
          border-radius: 5px;
          cursor: pointer;
        }
        .hightLight-td:hover {
          background-color: #aeb7e59c;
        }

        .hightLight-success-td {
          background-color: rgba(46, 119, 26, 0.976);
          color: #ffffff;
          border: 1px solid rgba(56, 148, 31, 0.525);
          padding: 4px 6px;
          border-radius: 5px;
          cursor: pointer;
        }
        .hightLight-success-td:hover {
          background-color: rgba(46, 119, 26, 0.811);
          border: rgba(46, 119, 26, 0.811);
        }

        .hightLight-danger-td {
          background-color: #b6313c;
          color: #ffffff;
          border: 1px solid #7c1528;
          padding: 4px 6px;
          border-radius: 5px;
          cursor: pointer;
        }

        .hightLight-danger-td:hover {
          background-color: #b6313cc2;
          border: #b6313cc2;
        }

        .hightLight-warning-td {
          background-color: #a9a926;
          color: #ffffff;
          border: 1px solid #899c18;
          padding: 4px 6px;
          border-radius: 5px;
          cursor: pointer;
        }

        .hightLight-warning-td:hover {
          background-color: #a9a926bf;
          border: #a9a926bf;
        }

        .hightLight-primary-td {
          background-color: #0d6efd;
          color: #ffffff;
          border: 1px solid #191b6b;
          padding: 4px 6px;
          border-radius: 5px;
          cursor: pointer;
        }

        .hightLight-primary-td:hover {
          background-color: #0d68e9bf;
          border: #09367dbf;
        }
      }
    }
  }

  .view-more-text {
    font-weight: 700;
    font-size: 14px;
    color: #2e3c83;
    cursor: pointer;
  }
}
