.login-card-body {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  @-webkit-keyframes lightSpeedIn {
    0% {
      transform: translateX(-30%);
      opacity: 0;
    }
    30% {
      transform: translateX(0%);
      opacity: 1;
    }

    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  @keyframes lightSpeedIn {
    0% {
      transform: translateX(-30%);
      opacity: 0;
    }
    30% {
      transform: translateX(0%);
      opacity: 1;
    }

    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }
}

.login-card-body.lightSpeedIn {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.shakeAlert {
  animation: shakeContainer 0.4s !important;
  animation-iteration-count: 4s;
  background-color: #ffffff;
  background-color: #ffe53b;
  background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%);
  color: #ffffff !important;
}

@keyframes shakeContainer {
  0% {
    transform: translate(1px, 1px) rotate(0deg) scale(1.2);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg) scale(1.2);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg) scale(1.2);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg) scale(1.2);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg) scale(1.2);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg) scale(1.2);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg) scale(1.2);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg) scale(1.2);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg) scale(1.2);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg) scale(1.2);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg) scale(1.2);
  }
}

/* LOADER */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 6px solid #bfbfbf !important;
  border-top: 6px solid #2e3c83 !important;
  border-radius: 50% !important;
  width: 35px;
  height: 35px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* LOADER */
.inputFieldLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #bfbfbf !important;
  border-top: 3px solid #2e3c83 !important;
  border-radius: 50% !important;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

/* Blur loader background */

.blur-loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* MODAL */
/* Blur effect for the background */
.modal-blur {
  filter: blur(5px);
  pointer-events: none;
}

/* Adjust the modal position when the backdrop is blurred */
.modal.fade .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.modal.fade.show .modal-dialog {
  animation-name: zoomIn;
}

.modal.fade .modal-dialog {
  animation-name: zoomOut;
}

@keyframes zoomIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

/* TABLE */
td {
  transition: all 0.2s ease-in-out;
  animation: table-animation linear 0.2s;
}

@keyframes table-animation {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

/* INPUT INVALID */
.invalid-input {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.6rem #ff0000;
}
/* INPUT INVALID */
.valid-input {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: inset 0 0 0.6rem #5cdd53;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}
