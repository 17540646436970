.add-btn{
    color:#aeb7e5;
     cursor:pointer;
}

.add-btn:hover{
    transform: scale(1.1); 
}

.add-star:after {
    content:"*";
    color:red;
    // padding: 0px 5px;
  }

.my-cursor-disabled{
    cursor:not-allowed !important;
}