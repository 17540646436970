@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import "./abstract/colors";
@import "./abstract/fonts";
@import "./abstract/mixin";
@import "./abstract/variables";
@import "./abstract/animations";

@import "./components/admin";
@import "./components/Buyer/buyer.scss";
@import "./components/Buyer/buyerTable.scss";
@import "./components/Buyer/buyerDetailsContainer.scss";
@import "./components/anchor";

@import "./components/tables";
@import "./components/layout";
@import "./components/modal";

/* BUYER TABLE */
// @import "./components/buyerTable.scss";

/*LENDER TABLE */
@import "./components/lender";
@import "./components/lenderTables.scss";

// Generic Styles for btn amd other reusable components
@import "./components/generic.scss";
