
.kyc-lender{
    width:100vw;
    height:100vh;
    overflow: auto;
    .label-tittle{
        margin-top: 20px;
        label{
            font-weight: bold;
            font-size: 18px;
            color: #1573fc;
        }
    }
    .kyc-row{
        .label-field{
            margin-top: -27%;
            margin-left: 24%;
        }
        .input-field{
            height: 200px;
            width: 80%;
            border: 2px dashed gray;
            background-color: lightgray;
            border-radius: 7px;
            input{
                height: 100%;
                opacity: 0;
            }
        }
    }
}