.modal {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  border-radius: 9px;
}

// This is for Messages Like Success Failure
.msg-modal {
  background-color: rgba(5, 1, 1, 0.216);
  backdrop-filter: blur(0.7px);

  // border: 10px solid rgba(0, 0, 0, 0.479);

  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    
    // border: 3px solid black !important;
    // border:5px solid green;

  }
  .modal-content {
    // border: 10px solid rgb(255, 7, 7) !important;
    width: auto ;
    // height: 350px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    // border: 3px solid black !important;
    min-width: 350px;
    // max-height: 350px !important;
    // border:5px solid rgb(15, 7, 255);
  }
  .msg-wrapper {
    // height: 100vh;
    // width: 100%;
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #eee;
  }

  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
 
    // margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }

  .alerter__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    // stroke: #dfe16a;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .alerter {
    width: 56px;
    height: 56px;
    border-radius: 0%;
    display: block;
    stroke-width: 2;
    // stroke: #fff;
    stroke-miterlimit: 10;
    // margin: 10% auto;
    // box-shadow: inset 0px 0px 0px #daf062;
    animation: alertfill 0.4s ease-in-out 0.4s forwards,
    alertScale 0.3s ease-in-out 0.9s both;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  .alerter__check {
    // border: 3px solid black;
    // transform-origin: 70% 70%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes alertScale {
    0%{
      transform: rotate(0deg);
    }
    15%{
      transform: rotate(-20deg);
    }
    25%{
      transform: rotate(-10deg);
    }
    50%{
      // transform: scale3d(1.1, 1.1, 1);
      transform: rotate(30deg);
    }
    75%{
      // transform: scale3d(1.1, 1.1, 1);
      transform: rotate(30deg);
    }
    100% {
      transform: scale3d(1.1, 1.1, 1);
      transform: rotate(0deg);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
  }

  @keyframes alertfill {

    100% {
      fill: #807207;
    }
  }
}

.modal-export-pdf-button {
  outline: none;
  border-width: 0;
  background-color: $primary-color;
  color: $pure-white;
  padding: 10px 15px;
  border-radius: 12px;
}

.modal-title-text {
  color: $primary-color;
}

.admin-cam-modal-container {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #2e3c83;
  border-radius: 18px;

  .admin-user-cam-title {
    color: #56595a;
    font-size: 16px;
    font-weight: 700;
  }

  .admin-user-cam-title-data {
    color: #858585;
    font-size: 16px;
  }
}

.admin-user-modal-title-line {
  border: 2px solid $primary-color;
  width: 100%;
}
