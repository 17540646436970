.lender-table-container::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  // background-color: #ffffff00;
}

.lender-table-container {
  border-radius: 0px 6px 24px 24px !important;
  box-shadow: 0px 3px 6px #00000029;
  // background-color: #ffffff;
  width: 100% !important;

  display: flex;
  flex-direction: column;

  overflow-x: scroll;
  box-sizing: border-box;
  // border: 1px solid black;

  .table-inner-cont {
    // border: 2px solid black;
    // width: 100%;
    // border-radius: 0px 6px 24px 24px ;
    box-sizing: border-box;
    // box-shadow: 0px 3px 6px #00000029;
    // box-sizing: border-box;
    width: 100% !important;
    // overflow-x: scroll;
    // flex-flow: row wrap !important;
  }

  .lender-dashboard-table {
    width: 100%;
    // border: 1px solid #ffffff;
    border-radius: 24px;
    max-width: 100vw;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #aeb7e5;
      border-radius: 24px;
      tr {
        text-align: center;
        // th{
        //   width: 20%;
        // }
        // td{
        //   width:'20%'
        // }
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;

        td {
          color: #575757;
        }

        td:first-child {
          color: #3e3e3e;
          font-weight: 600;
          font-size: 14px;
        }

        td:last-child {
          cursor: pointer;
          span {
            color: #08710f;
            font-weight: 600;
            background-color: #d2f0bf91;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
          }
        }
        td:nth-child(3) {
          cursor: pointer;
          transition: all 0.2s ease-in-out;
        }

        td:nth-child(3):hover {
          color: #2e3c83;
          font-weight: 800;
        }
      }
    }
  }

  .lender-anchor-and-lender-mapped-table {
    width: 100%;
    // border: 1px solid #ffffff;
    border-radius: 8px !important;
    max-width: 100vw;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #aeb7e5;
      border-radius: 24px;

      tr {
        text-align: center;
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;

        td {
          color: #3e3e3e;
          font-weight: 600;
          font-size: 16px;
        }

        td:last-child {
          span {
            color: #08710f;
            font-weight: 600;
            background-color: #d2f0bf91;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
          }
        }
      }
    }
  }

  .lender-anchor-and-lender-mapped-table1 {
    width: 150%;
    // border: 1px solid #ffffff;
    border-radius: 8px !important;
    max-width: 109vw;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #aeb7e5;
      border-radius: 24px;

      tr {
        text-align: center;
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;
        .cursor-pointer {
          cursor: pointer;
        }
        td {
          color: #3e3e3e;
          font-weight: 600;
          font-size: 16px;
          #Create {
            background-color: #eefbf8;
            color: #297c3d;
            padding: 5px 15px;
            border-radius: 7px;
          }
          #Create4 {
            background-color: #e5f2f8;
            color: #70c1e4;
            padding: 5px 15px;
            border-radius: 7px;
            margin-left: 10px;
          }
          #Create5 {
            background-color: #fed6d6;
            color: #f57374;
            padding: 5px 15px;
            border-radius: 7px;
            margin-left: 10px;
          }
          #Create1 {
            background-color: #eaf0f6;
            color: #508bc8;
            padding: 5px 15px;
            border-radius: 7px;
            margin-left: -100%;
          }
          .InputField {
            border: none;
            opacity: 0;
            width: 155px;
            // display: contents;
          }
          #Create2 {
            background-color: #f1fafc;
            color: #54a3b8;
            padding: 5px 15px;
            border-radius: 7px;
          }
          #Create2 {
            border: 2px solid #2fc6ab;
            color: #2fc6ab;
            padding: 5px 15px;
            border-radius: 7px;
          }
        }

        td:last-child {
          span {
            color: #08710f;
            font-weight: 600;
            background-color: #d2f0bf91;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
          }
        }
      }
    }
  }

  .lender-anchor-and-lender-mapped-table2 {
    width: 200%;
    // border: 1px solid #ffffff;
    border-radius: 8px !important;
    max-width: 200vw;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #aeb7e5;
      border-radius: 24px;

      tr {
        text-align: center;
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;
        .cursor-pointer {
          cursor: pointer;
        }
        td {
          color: #3e3e3e;
          font-weight: 600;
          font-size: 16px;
          #Create {
            background-color: #eefbf8;
            color: #297c3d;
            padding: 5px 15px;
            border-radius: 7px;
          }
          #Create4 {
            background-color: #e5f2f8;
            color: #70c1e4;
            padding: 5px 15px;
            border-radius: 7px;
            margin-left: 10px;
          }
          #Create5 {
            background-color: #fed6d6;
            color: #f57374;
            padding: 5px 15px;
            border-radius: 7px;
            margin-left: 10px;
          }
          #Create1 {
            background-color: #eaf0f6;
            color: #508bc8;
            padding: 5px 15px;
            border-radius: 7px;
          }
          #Create2 {
            background-color: #f1fafc;
            color: #54a3b8;
            padding: 5px 15px;
            border-radius: 7px;
          }
          #Create2 {
            border: 2px solid #2fc6ab;
            color: #2fc6ab;
            padding: 5px 15px;
            border-radius: 7px;
          }
        }

        td:last-child {
          span {
            color: #08710f;
            font-weight: 600;
            background-color: #d2f0bf91;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
          }
        }
      }
    }
  }

  .lender-users-data-table {
    width: 100%;
    // border: 1px solid #ffffff;
    border-radius: 24px;
    max-width: 100vw;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #aeb7e5;
      border-radius: 24px;
      tr {
        text-align: center;
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;

        td {
          color: #3e3e3e;
          // font-weight: 600;
          font-size: 16px;
        }

        td:last-child {
          span {
            color: #08710f;
            font-weight: 600;
            background-color: #d2f0bf91;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
          }
        }

        td:nth-child(3):hover {
          color: #2e3c83;
          font-weight: 800;
          cursor: pointer;
        }
      }
    }

    .sub-table-expand-button {
      transition: all 0.2s ease-in-out;
      transform: rotate(45deg);
      color: #ec7373;
    }

    // .lender-user-expand-row {
    //   height: 0;
    //   transition: max-height 0.3s;
    // }
    // .lender-user-expand-row-active {
    //   height: 100px;
    //   transition: max-height 0.6s;
    // }
  }

  .lender-channel-partner-data-table {
    width: 100%;
    // border: 1px solid #ffffff;
    border-radius: 24px;
    max-width: 100vw;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #aeb7e5;
      border-radius: 24px;
      tr {
        text-align: center;
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;

        td {
          color: #3e3e3e;
          // font-weight: 600;
          font-size: 16px;
        }

        td:last-child {
          span {
            color: #08710f;
            font-weight: 600;
            background-color: #d2f0bf91;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
          }
        }

        td:nth-child(2):hover {
          color: #2e3c83;
          font-weight: 800;
          cursor: pointer;
        }
      }
    }

    .sub-table-expand-button {
      transition: all 0.2s ease-in-out;
      transform: rotate(45deg);
      color: #ec7373;
    }
  }

  .view-more-text {
    font-weight: 700;
    font-size: 14px;
    color: #2e3c83;
    cursor: pointer;
  }
}

.page-data-text-container {
  font-weight: 700;
  font-size: 16px;
  color: #2e3c83;

  .page-data-text {
    color: #454548;
  }
}

.active-sort-text {
  color: #2e3c83;
}

.down-arrow {
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}

.up-arrow {
  transition: all 0.2s ease-in-out;
  transform: rotate(180deg);
}

.vertical {
  border-left: 3px dotted black;
  height: 40px;
  margin-left: 10px;
}
.horizontal {
  border-top: 1px solid black;
  height: 0;
  width: 10px;
  margin-top: 10px;
}
