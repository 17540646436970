.table-container {
  overflow-x: auto !important;
  border-radius: 6px 6px 24px 24px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #ffffff;
  //
  // min-width: 100%;
  //
  width: 100%;

  /* ADMIN TABLES */
  .admin-dashboard-table {
    width: 100%;
    // border: 1px solid #ffffff;
    border-radius: 0px;
    // max-width: 100vw;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #aeb7e5;
      border-radius: 24px;
      tr {
        text-align: center;
        // th{
        //   width: 20%;
        // }
        // td{
        //   width:'20%'
        // }
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;

        td {
          color: #575757;

          .success-btn{
            color: #08710f;
            font-weight: 600;
            background-color: #d2f0bf91;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
            cursor: pointer;
          }
          .warning-btn{
            color: #928d03;
            font-weight: 600;
            background-color: #f1f51591;
            padding: 5px 10px;
            border: 1px solid #ebef0b;
            border-radius: 6px;
            cursor: pointer;
          }
          .danger-btn{
            color: #720411;
            font-weight: 600;
            background-color: #d42f2f91;
            padding: 5px 10px;
            border: 1px solid #d42f2f91;
            border-radius: 6px;
            cursor: pointer;
          }
          .highlight-btn{
            color: #0c1c6c;
            font-weight: 600;
            background-color: #aeb7e5b8;
            padding: 5px 10px;
            border: 1px solid #a5aede;
            border-radius: 6px;
            cursor: pointer;
          }
          .success-btn:hover{
            color: #08710fab;
            font-weight: 600;
            background-color: #d2f0bffb;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
            cursor: pointer;
          }
          .warning-btn:hover{
            color: #4844028b;
            font-weight: 600;
            background-color: #c2c508cb;
            padding: 5px 10px;
            border: 1px solid #ebef0b;
            border-radius: 6px;
            cursor: pointer;
          }
          .danger-btn:hover{
            color: #69030f95;
            font-weight: 600;
            background-color: #d74949d8;
            padding: 5px 10px;
            border: 1px solid  #d74949d8;
            border-radius: 6px;
            cursor: pointer;
          }
          .highlight-btn:hover{
            color: #0c1c6ca9;
            font-weight: 600;
            background-color: #aeb7e5e2;
            padding: 5px 10px;
            border: 1px solid #aeb7e5e2;
            border-radius: 6px;
            cursor: pointer;
          }
          
        }

        td:first-child {
          color: #3e3e3e;
          font-weight: bold;
          font-size: 12px;
        }

        // td:last-child {
        //   cursor: pointer;
        //   span {
        //     color: #08710f;
        //     font-weight: 600;
        //     background-color: #d2f0bf91;
        //     padding: 5px 10px;
        //     border: 1px solid #d9eace;
        //     border-radius: 6px;
        //   }
        // }
        td:nth-child(3) {
          cursor: pointer;
          transition: all 0.2s ease-in-out;
        }

        td:nth-child(3):hover {
          color: #2e3c83;
          font-weight: 800;
        }
      }
    }
  }

  .admin-anchor-and-lender-mapped-table {
    width: 100%;
    // border: 1px solid #ffffff;
    border-radius: 8px !important;
    max-width: 100vw;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #aeb7e5;
      border-radius: 24px;
      tr {
        text-align: center;
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;

        td {
          color: #3e3e3e;
          font-weight: 600;
          font-size: 16px;


          .success-btn{
            color: #08710f;
            font-weight: 600;
            background-color: #d2f0bf91;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
            cursor: pointer;
          }
          .warning-btn{
            color: #928d03;
            font-weight: 600;
            background-color: #f1f51591;
            padding: 5px 10px;
            border: 1px solid #ebef0b;
            border-radius: 6px;
            cursor: pointer;
          }
          .danger-btn{
            color: #720411;
            font-weight: 600;
            background-color: #d42f2f91;
            padding: 5px 10px;
            border: 1px solid #d42f2f91;
            border-radius: 6px;
            cursor: pointer;
          }
          .highlight-btn{
            color: #0c1c6c;
            font-weight: 600;
            background-color: #aeb7e5b8;
            padding: 5px 10px;
            border: 1px solid #a5aede;
            border-radius: 6px;
            cursor: pointer;
          }
          .success-btn:hover{
            color: #08710fab;
            font-weight: 600;
            background-color: #d2f0bffb;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
            cursor: pointer;
          }
          .warning-btn:hover{
            color: #4844028b;
            font-weight: 600;
            background-color: #c2c508cb;
            padding: 5px 10px;
            border: 1px solid #ebef0b;
            border-radius: 6px;
            cursor: pointer;
          }
          .danger-btn:hover{
            color: #69030f95;
            font-weight: 600;
            background-color: #d74949d8;
            padding: 5px 10px;
            border: 1px solid  #d74949d8;
            border-radius: 6px;
            cursor: pointer;
          }
          .highlight-btn:hover{
            color: #0c1c6ca9;
            font-weight: 600;
            background-color: #aeb7e5e2;
            padding: 5px 10px;
            border: 1px solid #aeb7e5e2;
            border-radius: 6px;
            cursor: pointer;
          }
        }

        // td:last-child {
        //   span {
        //     color: #08710f;
        //     font-weight: 600;
        //     background-color: #d2f0bf91;
        //     padding: 5px 10px;
        //     border: 1px solid #d9eace;
        //     border-radius: 6px;
        //   }
        // }
      }
    }
  }

  .admin-users-data-table {
    width: 100%;
    // border: 1px solid #ffffff;
    border-radius: 24px;
    max-width: 100vw;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #aeb7e5;
      border-radius: 24px;
      tr {
        text-align: center;
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;

        td {
          color: #3e3e3e;
          // font-weight: 600;
          font-size: 16px;
          .success-btn{
            color: #08710f;
            font-weight: 600;
            background-color: #d2f0bf91;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
            cursor: pointer;
          }
          .warning-btn{
            color: #928d03;
            font-weight: 600;
            background-color: #f1f51591;
            padding: 5px 10px;
            border: 1px solid #ebef0b;
            border-radius: 6px;
            cursor: pointer;
          }
          .danger-btn{
            color: #720411;
            font-weight: 600;
            background-color: #d42f2f91;
            padding: 5px 10px;
            border: 1px solid #d42f2f91;
            border-radius: 6px;
            cursor: pointer;
          }
          .highlight-btn{
            color: #0c1c6c;
            font-weight: 600;
            background-color: #aeb7e5b8;
            padding: 5px 10px;
            border: 1px solid #a5aede;
            border-radius: 6px;
            cursor: pointer;
          }
          .success-btn:hover{
            color: #08710fab;
            font-weight: 600;
            background-color: #d2f0bffb;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
            cursor: pointer;
          }
          .warning-btn:hover{
            color: #4844028b;
            font-weight: 600;
            background-color: #c2c508cb;
            padding: 5px 10px;
            border: 1px solid #ebef0b;
            border-radius: 6px;
            cursor: pointer;
          }
          .danger-btn:hover{
            color: #69030f95;
            font-weight: 600;
            background-color: #d74949d8;
            padding: 5px 10px;
            border: 1px solid  #d74949d8;
            border-radius: 6px;
            cursor: pointer;
          }
          .highlight-btn:hover{
            color: #0c1c6ca9;
            font-weight: 600;
            background-color: #aeb7e5e2;
            padding: 5px 10px;
            border: 1px solid #aeb7e5e2;
            border-radius: 6px;
            cursor: pointer;
          }           
        }
      }
    }
    

    .sub-table-expand-button {
      transition: all 0.2s ease-in-out;
      transform: rotate(45deg);
      color: #ec7373;
    }

    // .admin-user-expand-row {
    //   height: 0;
    //   transition: max-height 0.3s;
    // }
    // .admin-user-expand-row-active {
    //   height: 100px;
    //   transition: max-height 0.6s;
    // }
  }

  .admin-channel-partner-data-table {
    width: 100%;
    // border: 1px solid #ffffff;
    border-radius: 24px;
    max-width: 100vw;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #aeb7e5;
      border-radius: 24px;
      tr {
        text-align: center;
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;

        td {
          color: #3e3e3e;
          // font-weight: 600;
          font-size: 16px;
          .success-btn{
            color: #08710f;
            font-weight: 600;
            background-color: #d2f0bf91;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
            cursor: pointer;
          }
          .warning-btn{
            color: #928d03;
            font-weight: 600;
            background-color: #f1f51591;
            padding: 5px 10px;
            border: 1px solid #ebef0b;
            border-radius: 6px;
            cursor: pointer;
          }
          .danger-btn{
            color: #720411;
            font-weight: 600;
            background-color: #d42f2f91;
            padding: 5px 10px;
            border: 1px solid #d42f2f91;
            border-radius: 6px;
            cursor: pointer;
          }
          .highlight-btn{
            color: #0c1c6c;
            font-weight: 600;
            background-color: #aeb7e5b8;
            padding: 5px 10px;
            border: 1px solid #a5aede;
            border-radius: 6px;
            cursor: pointer;
          }
          .success-btn:hover{
            color: #08710fab;
            font-weight: 600;
            background-color: #d2f0bffb;
            padding: 5px 10px;
            border: 1px solid #d9eace;
            border-radius: 6px;
            cursor: pointer;
          }
          .warning-btn:hover{
            color: #4844028b;
            font-weight: 600;
            background-color: #c2c508cb;
            padding: 5px 10px;
            border: 1px solid #ebef0b;
            border-radius: 6px;
            cursor: pointer;
          }
          .danger-btn:hover{
            color: #69030f95;
            font-weight: 600;
            background-color: #d74949d8;
            padding: 5px 10px;
            border: 1px solid  #d74949d8;
            border-radius: 6px;
            cursor: pointer;
          }
          .highlight-btn:hover{
            color: #0c1c6ca9;
            font-weight: 600;
            background-color: #aeb7e5e2;
            padding: 5px 10px;
            border: 1px solid #aeb7e5e2;
            border-radius: 6px;
            cursor: pointer;
          }

        }

        // td:last-child {
        //   span {
        //     color: #08710f;
        //     font-weight: 600;
        //     background-color: #d2f0bf91;
        //     padding: 5px 10px;
        //     border: 1px solid #d9eace;
        //     border-radius: 6px;
        //   }
        // }

        td:nth-child(2):hover {
          color: #2e3c83;
          font-weight: 800;
          cursor: pointer;
        }
      }
    }

    .sub-table-expand-button {
      transition: all 0.2s ease-in-out;
      transform: rotate(45deg);
      color: #ec7373;
    }
  }

  /* LENDER TABLES */
  .lender-bill-loading-table {
    width: 200%;
    border-radius: 24px;
    overflow-x: scroll;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #aeb7e5;
      border-radius: 24px;
      tr {
        text-align: center;
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;

        td {
          color: #3e3e3e;
          // font-weight: 600;
          font-size: 16px;
        }

        // td:last-child {
        //   span {
        //     color: #08710f;
        //     font-weight: 600;
        //     background-color: #d2f0bf91;
        //     padding: 5px 10px;
        //     border: 1px solid #d9eace;
        //     border-radius: 6px;
        //   }
        // }

        td:nth-child(2):hover {
          color: #2e3c83;
          font-weight: 800;
          cursor: pointer;
        }
      }
    }

    td {
      width: 120px;
    }

    .sub-table-expand-button {
      transition: all 0.2s ease-in-out;
      transform: rotate(45deg);
      color: #ec7373;
    }
  }


  /* ANCHOR TABLES */
  .admin-anchor-digital-invoice-table {
    width: 200%;
    // border: 1px solid #ffffff;
    border-radius: 8px !important;
    max-width: 100vw;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #aeb7e5;
      border-radius: 24px;
      tr {
        text-align: center;
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;

        td {
          color: #3e3e3e;
          font-weight: 600;
          font-size: 16px;
        }

        .upload-button-container {
          label {
            font-weight: 600;
            padding: 0 0 0 8px;
            border-radius: 6px;
            width: fit-content;
            background-color: #d5dbf7;
            color: #5a71e9;

            span {
              background-color: #9faffa;
              padding: 6px 12px;
              border-radius: 6px;
            }
          }
        }

        .file-exist-container {
          label {
            font-weight: 600;
            padding: 0 0 0 8px;
            border-radius: 6px;
            width: fit-content;
            background-color: #dbfce1;
            color: #39e739;
          }

          span {
            background-color: #a4fab3;
            padding: 0 6px;
            border-radius: 6px;
          }
        }
      }
    }
  }

  .admin-anchor-manual-invoice-table {
    width: 200%;
    // border: 1px solid #ffffff;
    border-radius: 8px !important;
    max-width: 100vw;

    thead {
      background-color: #aeb7e5;
      height: 50px;
      color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #aeb7e5;
      border-radius: 24px;
      tr {
        text-align: center;
      }
    }

    tbody {
      background-color: #ffffff;
      tr {
        text-align: center;
        height: 50px;

        td {
          color: #3e3e3e;
          font-weight: 600;
          font-size: 16px;
        }

        .upload-button-container {
          label {
            font-weight: 600;
            padding: 0 0 0 8px;
            border-radius: 6px;
            width: fit-content;
            background-color: #d5dbf7;
            color: #5a71e9;

            span {
              background-color: #9faffa;
              padding: 6px 12px;
              border-radius: 6px;
            }
          }
        }

        .file-exist-container {
          label {
            font-weight: 600;
            padding: 0 0 0 8px;
            border-radius: 6px;
            width: fit-content;
            background-color: #dbfce1;
            color: #39e739;
          }

          span {
            background-color: #a4fab3;
            padding: 0 6px;
            border-radius: 6px;
          }
        }
      }
    }
  }

 
}

 /* OTHERS */
 .view-more-text {
  font-weight: 700;
  font-size: 14px;
  color: #2e3c83;
  cursor: pointer;
}



.page-data-text-container {
  font-weight: 700;
  font-size: 16px;
  color: #2e3c83;

  .page-data-text {
    color: #454548;
  }
}

.active-sort-text {
  color: #2e3c83;
}

.down-arrow {
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}

.up-arrow {
  transition: all 0.2s ease-in-out;
  transform: rotate(180deg);
}

.vertical {
  border-left: 3px dotted black;
  height: 40px;
  margin-left: 10px;
}

.horizontal {
  border-top: 1px solid black;
  height: 0;
  width: 10px;
  margin-top: 10px;
}

.no-data{
  font-size: large;
  margin: auto;
  padding: 10px;
  text-align: center;
  width: 200%;
}



.userCompanyName:hover{
  color: #0000b3 !important;
  font-size: 1.1rem !important;
}

.userCustomerId:hover{
  color: #0000b3 !important;
  font-size: 1.1rem !important;
}