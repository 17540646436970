.admin-dashboard-card-container {
  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;

  .admin-dashboard-card {
    background-color: #e6e7ed;
    box-shadow: 0px 3px 6px #00000012;
    border-radius: 13px;
    //min-width: 275px;
    //width: 230px;
    height: fit-content;
    width: 100%;
    transition: all 0.2s ease-in-out;

    .card-title {
      color: #3b3b3b;
      font-size: 14px;
      // font-weight: 600;
    }

    .card-line {
      border: 1px solid white;
    }

    .card-count {
      color: #3b3b3b;
      font-size: 24px;
      font-weight: 800;
    }

    .card-status {
      color: #2e3c83;
      font-size: 14px;
      font-weight: 500;
    }

    .card-icon{
      width: 15%;
      height: 15%;
      padding-top: 10%;
      display: flex;
      justify-content: end;
      
    }
  }

  .admin-dashboard-card:hover {
    transform: scale(1.1);
    opacity: 1;
  }

  // for Admin Anchor And Lender
  .anchor-and-lender-highlight-title {
    font-size: 16px;
    font-weight: 500;
    color: #8089b8;
    font-weight: 500;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
  }

  .anchor-and-lender-done-button {
    font-size: 16px;
    font-weight: 500;
    color: #8089b8;
    border: 2px solid #8089b8;
  }
  .anchor-and-lender-done-button:hover {
    background-color: #8089b8;
    border: 2px solid #8089b8;
  }

  .anchor-and-lender-select-label {
    font-size: 14px;
    font-weight: 800;
    color: #414141;
  }

  // for Admin Relationship Manager
  .rm-add-button {
    display: none;
    background-color: #aeb7e5;
    color: #ffffff;
    padding: 4px 25px;
    border-radius: 8px;
    position: relative;
    left: 15px;
    transition: all 0.2s ease-in-out;
    font-weight: 800;
  }

  .rm-add-button.show {
    display: block;
  }

  .rm-plus-button {
    font-size: 30px;
    color: #2e3c83;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    &:hover {
      transform: scale(1.1);
    }
  }

  .rm-light-plus-button {
    font-size: 30px;
    color: #0e1432;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    &:hover {
      transform: scale(1.1);
    }
  }

  .view-more-text {
    font-weight: 700;
    font-size: 14px;
    color: #2e3c83;
    cursor: pointer;
  }
}

// .lender-dashboard-card-container {
//   border-radius: 18px;
//   background-color: #ffffff;
//   box-shadow: 0px 3px 6px #00000029;

//   .lender-dashboard-card {
//     background-color: #e6e7ed;
//     box-shadow: 0px 3px 6px #00000012;
//     border-radius: 13px;
//     // min-width: 275px;
//     // width: 230px;
//     height: fit-content;
//     width: 100%;
//     transition: all 0.2s ease-in-out;

//     .card-title {
//       color: #3b3b3b;
//       font-size: 14px;
//       font-weight: 600;
//     }

//     .card-line {
//       border: 2px solid white;
//     }

//     .card-count {
//       color: #3b3b3b;
//       font-size: 24px;
//       font-weight: 800;
//     }

//     .card-status {
//       color: #2e3c83;
//       font-size: 14px;
//       font-weight: 600;
//     }
//   }

//   .lender-dashboard-card:hover {
//     transform: scale(1.1);
//     opacity: 1;
//   }

//   // for Admin Anchor And Lender
//   .anchor-and-lender-highlight-title {
//     font-size: 16px;
//     font-weight: 500;
//     color: #8089b8;
//     font-weight: 500;
//     text-decoration-line: underline;
//     text-decoration-thickness: 2px;
//     text-underline-offset: 5px;
//   }

//   .anchor-and-lender-done-button {
//     font-size: 16px;
//     font-weight: 500;
//     color: #8089b8;
//     border: 2px solid #8089b8;
//   }
//   .anchor-and-lender-done-button:hover {
//     background-color: #8089b8;
//     border: 2px solid #8089b8;
//   }

//   .anchor-and-lender-select-label {
//     font-size: 14px;
//     font-weight: 800;
//     color: #414141;
//   }

//   // for Admin Relationship Manager
//   .rm-add-button {
//     display: none;
//     background-color: #aeb7e5;
//     color: #ffffff;
//     padding: 4px 25px;
//     border-radius: 8px;
//     position: relative;
//     left: 15px;
//     transition: all 0.2s ease-in-out;
//     font-weight: 800;
//   }

//   .rm-add-button.show {
//     display: block;
//   }

//   .rm-plus-button {
//     font-size: 30px;
//     color: #2e3c83;
//     transition: all 0.3s ease-in-out;
//     z-index: 1;
//     &:hover {
//       transform: scale(1.1);
//     }
//   }
// }

.table_View_Data {
  .admin-dashboard-table {
    .Model_blocks {
      display: flex;
      width: 100%;
    }
  }
}

.search-container {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ffffff;
  border-radius: 11px;
  // width: 100%;
  min-width: 250px;
  height: 40px;

  .search-input-element {
    border-width: 0;
    outline: none;
    border-radius: 11px;
  }
}
.search-input-element1 {
  border-width: 0;
  outline: none;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  border: 1px solid lightgray;
}

.admin-dashboard-content-title {
  font-size: 25px;
  font-weight: bold;
  color: #3b3b3b;
  display: flex;
  align-items: center;
}

.admin-dashboard-table-title {
  font-size: 18px;
  font-weight: 500;
  color: #3b3b3b;
  cursor: pointer;
  border-radius: 8px 8px 0px 0px;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-table-title-hover:hover {
  // transform: scale3d(1.2);
  // transform: translate3d(0px, 0px, 0px);
  // font-weight: 800;
  background-color: #e0e1e6;
  // border-top: 1px solid #aeb7e5;
  // border-right: 1px solid #aeb7e5;
  // border-left: 1px solid #aeb7e5;
}

.table-container{
  
  .table-header{
    font-size: 18px;

    .each-header{
      padding: 0px 35px;
    }
  }

  .each-body{
    font-size: 16px;
    height: 65px !important;
    border-bottom: 1px solid grey;
  }

}
.status{
  color: #087110;
  background-color: #E5F6DC;
  border-radius: 5px;
  border: 1px solid #E7F1E0;
}

.active-table-data {
  color: #ffffff;
  background-color: #aeb7e5;
}

.admin-kyc-card-container {
  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;

  .kyc-content-title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  .details-container {
    box-shadow: 0px 3px 6px #00000012;
    border: 1px solid #ffffff;
    border-radius: 24px;
    padding: 20px;

    .kyc-fields-container {
      // display: flex;
      // flex-direction: column;
      // gap: 4px;
      // width: 100%;
      min-width: 200px;
      max-width: 100%;
      #labelEdit {
        font-size: 30px;
      }

      .kyc-label-text {
        color: #414141;
        font-size: 16px;
      }

      .kyc-input-field {
        border: 1px solid #a3a4a7;
        border-radius: 6px;
        width: 100%;
      }
    }
  }

  .kyc-back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 35px;
    font-weight: 800;
    background-color: #2e3c83;
    color: #ffffff;
    border: 1px solid #2e3c83;
    border-radius: 21px;
  }
}

.mca-data-card {
  width: 100%;
  border: 2px solid #b8b6b6;
  border-radius: 5px;
  background-color: #f1efef;
  padding: 0.5rem;
  .mca-data-section {
    width: 100%;
  }
  table,
  th,
  td {
    border: 1px solid #000;
  }
  .numLeft {
    text-align: right;
    padding-right: 0.5rem;
  }
}

.numbrLeft {
  text-align: right;
  padding-right: 0.5rem;
}

.cam-data-card {
  width: 100%;
  height: 100%;
  border: 2px solid #cecdcd;
  border-radius: 5px;
  background-color: #f1efef;
  padding: 0.5rem;
}

//select in audit trail

.select-container {
  background-color: #ffffff !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #ffffff !important;
  border-radius: 11px !important;
  width: auto !important;
  min-width: 250px !important;
  height: 40px !important;
  margin-right: 10px !important;
}
