.request-details-cont::-webkit-scrollbar {
  display: none;
}

.request-details-cont {
  
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  overflow-y: auto;

  .container-with-heading {

    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 12px 12px 8px 8px;
  }

  table {
    border-collapse: collapse;
    letter-spacing: 1px;

    th,
    td {
      border: 1px solid rgba(178, 175, 175, 0.746);
      padding: 10px 10px;
    }
  }

  .content-title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: #aeb7e5;
    color: white;
    height: 40px;
    border-radius: 12px 12px 0 0;
  }

  .details-container {
    padding: 20px;

    .fields-container {
      min-width: 200px;
      max-width: 100%;
      .label-text {
        color: #414141;
        font-size: 16px;
      }
      .input-field {
        border: 1px solid #a3a4a7;
        border-radius: 6px;
        width: 100%;
      }
    }
  }

  .back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 35px;
    font-weight: 800;
    background-color: #2e3c83;
    color: #ffffff;
    border: 1px solid #2e3c83;
    margin: 0px 10px;
    border-radius: 21px;
  }

  .success-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 35px;
    font-weight: 800;
    background-color: #489825;
    color: #ffffff;
    border: 1px solid #489825;
    margin: 0px 10px;
    border-radius: 21px;
  }

  .success-button:hover{
        background-color: #2d6017c6;
  }
  .back-button:hover{
    background-color: #1e2858;
    border: 1px solid #12216aa3;
  }
  .warning-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 35px;
    font-weight: 800;
    background-color: rgb(200, 154, 29);
    color: #ffffff;
    border: 1px solid rgb(90, 75, 1);
    margin: 0px 10px;
    border-radius: 21px;
  }


  .danger-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 35px;
    font-weight: 800;
    background-color: #76131398;
    color: #ffffff;
    border: 1px solid #76131398;
    margin: 0px 10px;
    border-radius: 21px;
  }

  .success-button-disabled {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 35px;
    font-weight: 800;
    background-color: #2a6a10;
    color: #ffffff;
    border: 1px solid #132c09;
    margin: 0px 10px;
    border-radius: 21px;
    cursor: not-allowed;
  }

  
  .danger-button:hover{
    box-shadow: inset 0px 0px 30px #b7b5b598;
  }
  .warning-button:hover{
    box-shadow: inset 0px 0px 30px #e2e2e2a7;
  }

  .generate-po {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 35px;
    font-weight: 800;
    background-color: #22c6ab;
    color: #ffffff;
    border: 1px solid #22c6ab;
    border-radius: 21px;
    margin: 0px 10px;
  }
}
