.buttonActive{
    background: #2e3c83;
    color: white;
    width: fit-content;
    padding: 5px 20px;
    font-weight: bold;
    border: 2px solid #2e3c83;
    cursor: pointer;
}

.buttonUnActive{
    background: white;
    color: #2e3c83;
    width: fit-content;
    padding: 5px 20px;
    font-weight: bold;
    border: 2px solid #2e3c83;
    cursor: pointer;
}

.input-number::-webkit-inner-spin-button {
    opacity: 1
}