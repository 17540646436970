@font-face {
  font-family: Lato;
  src: url("./assests/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("./assests/fonts/Montserrat-Regular.ttf");
}

* {
  font-family: Lato;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw !important;
  height: 100vh !important;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
}

@media screen and (max-width: 768px) {
  .body {
    overflow-y: hidden;
  }
}

@media screen and (min-width: 767px) {
  .body {
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*  */

table {
  /* width: 200% !important; */
}

th {
  /* padding: 0 10px !important; */
}

td {
  /* padding: 0 10px !important; */
}
