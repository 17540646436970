// .anchor_graph_cont {
//     height: 50vh;
//     width: 95%;
//     transition: all 0.2s ease-in-out;
//     box-shadow: 4px 6px 8px #00000029;
//     border-radius: 11px;
//     display: flex;
//     justify-content: center;
  
//     p{
//       font-size: 15px;
//       color: #707070;
//       font-family: Montserrat;
//       line-height: 35px;
//       // border: 1px solid black;
//       padding: 0px 0px 0px 3%;
//     }
  
//     p{
//       font-size: 1rem;
//       color: #707070;
//       font-family: Montserrat;
//       line-height: 35px;
//       // border: 1px solid black;
//       padding: 0px 0px 0px 3%;
//       border-bottom: 1px solid #0000005e;
//     }
//   }
  
//   .buyer_graph_cont:hover {
//     transform: scale(1.03);
//   }
  
//   @media only screen and (max-width: 991px) and (min-width: 768px) {
//     // .buyer_graph_cont {
//     //   max-height: 50vh;
//     //   width: 98%;
//     // }
//     .buyer_graph_cont:hover {
//       transform: scaleX(1.1);
//     }
//   }
  
//   @media only screen and (max-width: 767px) {
//     // .buyer_graph_cont {
//     //   max-height: 57vh;
//     //   width: 100%;
//     // }
//     .buyer_graph_cont:hover {
//       transform: scaleX(1.1);
//     }
//   }
  
  // .progress-bar-cont{
  //   height: 100%;
  //   overflow-y: scroll;
  //   scrollbar-width: none;
  // }
  
  // .progress-bar-cont::-webkit-scrollbar {
  //   width: 1em;
  // }
  

  .success-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 35px;
    font-weight: 800;
    background-color: #489825;
    color: #ffffff;
    border: 1px solid #489825;
    margin: 0px 10px;
    border-radius: 21px;
  }

  .success-button:hover{
        background-color: #2d6017c6;
  }

  .danger-button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 35px;
    font-weight: 800;
    background-color: rgb(200, 4, 29);
    color: #ffffff;
    border: 1px solid rgb(90, 75, 1);
    margin: 0px 10px;
    border-radius: 21px;
  }

  .danger-button:hover{
    background-color:  rgb(150, 30, 29);
}
  
  .back-button:hover{
    background-color: #1e2858;
    border: 1px solid #12216aa3;
  }
  .warning-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 35px;
    font-weight: 800;
    background-color: rgb(200, 154, 29);
    color: #ffffff;
    border: 1px solid rgb(90, 75, 1);
    margin: 0px 10px;
    border-radius: 21px;
    
    &:disabled {
      background-color: rgb(200, 154, 29);
      &:hover {
        background-color: rgb(200, 154, 29);
      }
    }
  }
  

  // --------- new dashboard


.anchor_graph_cont {
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 8px #00000029;
  border-radius: 11px;
  margin-bottom: 15px;
 

  .graph-head {
    font-size: 13px;
    color: #707070;
    font-family: Montserrat;
    line-height: 35px;
    border-bottom: 1px solid #707070;
    padding-left: 20px;
    margin: 0px 15px 0px 15px;
  }

  .graph-footer{
    font-size: 12px;
    color: #707070;
    font-family: Montserrat;
    line-height: 35px;
    text-align: end;
    margin: 0px;
    }

  .bar-container{
    margin-left: 30px;
    font-size: 13px;
    color: black;
    .data{
      display: none;
      top: 0px;
      right: 50px;
      border: solid 1px grey;
      border-radius: 10px;
      color: grey;
      padding: 5px;
      z-index: 5;
    }
}
.bar-container:hover{
  .data{
    display: block;
  }
}

.pie-detail{
  display: none;
  width: 95px;
  top: 95px;
  right: 140px;
  border: solid 1px grey;
  border-radius: 10px;
  color: grey;
  padding: 5px;
  z-index: 5;
  font-size: 12px;
  // background-color:rgb(244, 242, 242);
  background: rgba(0,0,0,0.05);


  .bold{
    font-weight: bold;
  }

  .one{
    background-color: red;
  }

  
}

.pie:hover{
  .pie-detail{
    display: block;
  }
}
  

  .anchor-dashboard-card {
    background-color: #e6e7ed;
    box-shadow: 0px 3px 6px #00000012;
    border-radius: 13px;
    // min-width: 275px;
    // width: 230px;
    height: fit-content;
    width: 100%;
    transition: all 0.2s ease-in-out;

    .card-title {
      color: #3b3b3b;
      font-size: 14px;
    }

    .card-line {
      border: 1px solid #FFFFFF;
    }

    .card-count {
      color: #3b3b3b;
      font-size: 24px;
      font-weight: 800;
    }

    .card-status {
      color: #2e3c83;
      font-size: 16px;
      font-weight: 500;
    }

    .card-icon{
      width: 15%;
      height: 15%;
      padding-top: 10%;
      display: flex;
      justify-content: end;
    }
  }

  .anchor-dashboard-card:hover {
    transform: scale(1.03);
    opacity: 1;
  }

  // for Admin Anchor And Lender
  .anchor-and-lender-highlight-title {
    font-size: 16px;
    font-weight: 500;
    color: #8089b8;
    font-weight: 500;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
  }

  .anchor-and-lender-done-button {
    font-size: 16px;
    font-weight: 500;
    color: #8089b8;
    border: 2px solid #8089b8;
  }
  .anchor-and-lender-done-button:hover {
    background-color: #8089b8;
    border: 2px solid #8089b8;
  }

  .anchor-and-lender-select-label {
    font-size: 14px;
    font-weight: 800;
    color: #414141;
  }

  // for Admin Relationship Manager
  .rm-add-button {
    display: none;
    background-color: #aeb7e5;
    color: #ffffff;
    padding: 4px 25px;
    border-radius: 8px;
    position: relative;
    left: 15px;
    transition: all 0.2s ease-in-out;
    font-weight: 800;
  }

  .rm-add-button.show {
    display: block;
  }

  .rm-plus-button {
    font-size: 30px;
    color: #2e3c83;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    &:hover {
      transform: scale(1.1);
    }
  }

  .rm-light-plus-button {
    font-size: 30px;
    color: #0e1432;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    &:hover {
      transform: scale(1.1);
    }
  }

  .view-more-text {
    font-weight: 700;
    font-size: 14px;
    color: #2e3c83;
    cursor: pointer;
  }
  
}

.anchor_graph_cont:hover {
  transform: scale(1.03);
}



.recharts-legend-item {
  margin: 20px 0px;
}


@media only screen and (max-width: 991px) and (min-width: 768px) {
  // .buyer_graph_cont {
  //   max-height: 50vh;
  //   width: 98%;
  // }
  .anchor_graph_cont:hover {
    transform: scaleX(1.1);
  }
}

@media only screen and (max-width: 767px) {
  // .buyer_graph_cont {
  //   max-height: 57vh;
  //   width: 100%;
  // }
  .anchor_graph_cont:hover {
    transform: scaleX(1.1);
  }
}

.anchor-progress-bar-cont{
  position: relative;

  @media only screen and (min-width: 1700px) {
    margin: 15px 0px 15px 0px;
  }

  @media only screen and (max-width:1699px) and (min-width: 1400px) {
    margin: 10px 0px 10px 0px;
  }

  .x-axis{
   transform: rotate(-90deg);
    position: absolute;
    top: 42%;
    left: -70px;
    font-size: 12px;
    color: #707070;
    font-family: Montserrat;
     width: 150px;
  }
}

.anchor-progress-bar-cont::-webkit-scrollbar {
  width: 1em;
}

.buyer-prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.box-shadow-red {
  box-shadow: 1px 2px 9px #f4aab9;
}

.progress{
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  position: relative;
}

.progress-bar{
  border-radius: 0px 5px 5px 0px;
}

.anchor-graph2-head {
  font-size: 12px;
  color: #707070;
  font-family: Montserrat;
  line-height: 35px;
  border-bottom: 1px solid #707070;
  margin: 0px 15px 0px 15px;
  padding-top: 12px;
}

.limit-container{
  margin-left: 10px;
  position: relative;
  font-size: 11px;
  height: 10px;

  .mark1{
    border: 0.5px solid #F3F3F3;
    z-index: -1;
    position: absolute;
    top: -133px;
    left: 5.5%;
    width: 0.5 px;
    height: 129px;
  }

  .mark2{
    border: 0.5px solid #F3F3F3;
    z-index: -1;
    position: absolute;
    top: -133px;
    left: 51.5%;
    width: 0.5 px;
    height: 129px;
  }

  .mark3{
    border: 0.5px solid #F3F3F3;
    z-index: -1;
    position: absolute;
    top: -133px;
    left: 98%;
    width: 0.5 px;
    height: 129px;
  }

  .limit{
    position: absolute;
    font-size: 11px;
    color: #707070;
    font-family: Montserrat;
  }
  
  .max-limit{
    position: absolute;
    right: 0px;
    font-size: 11px;
    color: #707070;
    font-family: Montserrat;
  }
}


.anchor-pie-chart-container{
  position: relative;

  .pie-chart{
    max-width: 400px;
    max-height: 400px;
  }

  .inner-circle{
    position: absolute;
    top: 0%;
    left: 0%;
  }

  .fin-year{
    position: absolute;
    top: 80%;
    left: 30%;
    font-size: 11px;
    color: #707070;
    font-family: Montserrat;
    margin: 0px;
    text-align: center;
    height: 30px;
  }
}

.small-circle{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: -13px 12px 0px 0px;
}

.anchor-label{
  width: 50%;
  margin: auto;

  @media only screen and (max-width: 1530px)and (min-width: 1198px) {
    width: 80%;
  }

  .due-name{
    color: black;
    font-size: 12px;
    color: #707070;
    font-family: Montserrat;
    margin: 0px;
    text-align: center;
    height: 30px;
    }
}

.footer{
  position: absolute;
  bottom: 5px;
  font-size: 0.8rem; 
  left: 55%;
}